<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :class="{ 'on-hover': hover }"
      :elevation="hover ? 5 : 0"
      width="100%"
      outlined
      @click.stop="show_conv = true"
    >
      <v-card-title
        class="text-h6 rojobb--text"
        style="word-break: normal"
      >
        {{ item.busqueda }}
      </v-card-title>
      <v-card-subtitle
        class="body-2 font-weight-medium pb-md-1 pb-0"
      >
        {{ item.fecha_desde_format }} - {{ item.fecha_hasta_format }} - {{ item.area_nombre }} - {{ item.provincia }}, {{ item.departamento }}, {{ item.localidad }}
      </v-card-subtitle>
      <v-card-actions class="d-flex justify-end">
        <template>
          <v-btn
            v-if="!cv"
            color="info"
            small
          >Ver</v-btn>
          <Convocatoria v-model="show_conv" :convocatoria="item" />
        </template>
        <div v-if="home" class="ml-2" @click.stop="show_conv = false">
          <Postularme :conv="item.id" :small="true" />
        </div>
      </v-card-actions>
      <v-divider v-if="!home"></v-divider>
      <v-card-text v-if="!home" class="body-1 font-weight-medium py-2">
        Se postuló el {{ item.fecha_post }}
        <v-spacer></v-spacer>
        <div v-if="cv">
          <div>
            Etapa alcanzada: {{ item.etapa }}
          </div>
          <div>
            Estado: {{ item.estado }}
          </div>
        </div>
        <div v-else>
          Estado: <span :class="item.etapa === 'Finalizada' ? 'error--text' : 'success--text'" >{{ item.etapa }}</span>
        </div>
      </v-card-text>
      
    </v-card>
  </v-hover>
</template>

<script>
import Convocatoria from '../components/Convocatoria'
import Postularme from '../components/utils/Postularme'

export default {
  data() {
    return {
      show_conv: false,
    }
  },
  props: {
    item: Object,
    home: {
      type: Boolean,
      default: false,
    },
    cv: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Convocatoria, Postularme
  }
}
</script>